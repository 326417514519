import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

// Si no estamos autenticados redirige al login
export const AuthGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const token = authService.getAccessToken();

  if (token && !isTokenExpired(token)) {
    return true;
  } else {
    return router.createUrlTree(['/login']);
  }

  function isTokenExpired(token: string): boolean {
    const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica el payload del token
    const expirationDate = new Date(payload.exp * 1000); // Convierte el claim `exp` a una fecha
    return expirationDate < new Date(); // Compara con la fecha actual
  }
};
