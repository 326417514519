import { Routes } from '@angular/router';
import { AuthenticatedGuard } from './core/guards/authenticated.guard';
import { AuthGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./shared/components/layout/layout.component'),
    children: [
      {
        path: '',
        loadComponent: () => import('./components/home/home.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'clientes',
        loadComponent: () => import('./clientes/components/clientes/clientes.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'clientes/:id',
        loadComponent: () => import('./clientes/components/cliente/cliente.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'clientes/nuevo',
        loadComponent: () => import('./clientes/components/cliente/cliente.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'maestros/tipo-documento',
        loadComponent: () =>
          import('./maestros/components/tipo-documento/tipo-documento.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'maestros/tipo-iva',
        loadComponent: () =>
          import('./maestros/components/tipo-iva/tipo-iva.component'),
        canActivate: [AuthGuard],
      },
      {
        path: 'maestros/tipo-via',
        loadComponent: () =>
          import('./maestros/components/tipo-via/tipo-via.component'),
        canActivate: [AuthGuard],
      }
    ],
  },
  {
    path: 'login',
    loadComponent: () => import('./core/components/login/login.component'),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
